import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import MainBody from './components/MainBody';
import Page404 from './components/Page404';
import { Context, SharedState } from './context/SharedState';
import './CSS/loader.css'
import './CSS/googleFonts.css'
import Loader from './components/Loader';
import MyPlansModal from './components/MyPlansModal';
import HireMe from './components/HireMe';
import TnC from './components/TnC';

function App() {
  const {loading} = useContext(Context) //put the <SharedState> in index.js to run

  useEffect(() => {
    //remove unnecessary hash "#" from URL pattern immediately when i scroll
    window.addEventListener('scroll', () => {
      if (window.location.hash && window.location.hash !== '#') {
        window.history.replaceState(null, null, window.location.pathname);
      }
    });
  
  }, [window.scrollY]);

  return (
    <>
      {loading ? (

        <Loader />

      ) : (
        <SharedState>
          <Router>
            <Navbar />
            <MyPlansModal />
            <Routes>

              <Route exact path="/" element={<MainBody />} />
              <Route exact path="/myplans/:id" element={<HireMe />} />
              <Route exact path="/terms" element={<TnC />} />
              <Route path="*" element={<Page404 />} />

            </Routes>
          </Router>
        </SharedState>
      )}
    </>
  );
}

export default App;
